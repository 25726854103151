<template>
  <BaseListPage locale-section="pages.physicalObjects" route="object">
    <PhysicalObjectTree />
  </BaseListPage>
</template>

<script>
export default {
  name: "PhysicalObjectsPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    PhysicalObjectTree: () => import("./PhysicalObjectTree")
  }
};
</script>
